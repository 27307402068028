import React from "react";
import Axios from "axios";

import API from "../../utils/api";
import ErrorAlert from "../../components/ErrorAlert";

export default function EditAccount({
  match: {
    params: { id },
  },
}) {
  const [form, setForm] = React.useState({ email: "", key: "" });
  const [updated, setUpdated] = React.useState(false);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    Axios.get(API.accounts.get(id))
      .then((resp) => {
        const { account, error } = resp.data;
        if (error) return setError(error);
        if (account) setForm(account);
      })
      .catch(setError);
  }, [id]);

  const proceed = (e) => {
    e.preventDefault();
    Axios.post(API.accounts.update(id), form)
      .then((resp) => {
        const { result, error } = resp.data;
        if (error) return setError(error);
        if (result && result.n) setUpdated(true);
      })
      .catch(setError);
  };

  return (
    <div className="edit-account m-4">
      <div className="shadow-sm bg-white p-3">
        <h1>Edit Account</h1>
        <ErrorAlert error={error} setError={setError} />
        {updated && (
          <div className="alert alert-success">The Account was updated!</div>
        )}
        <form onSubmit={proceed} className="form">
          <fieldset className="form-group">
            <label>Email</label>
            <input
              type="text"
              className="form-control"
              value={form.email}
              onChange={(e) => setForm({ ...form, email: e.target.value })}
            />
          </fieldset>
          <fieldset className="form-group">
            <label>Key</label>
            <input
              type="text"
              className="form-control"
              value={form.key}
              onChange={(e) => setForm({ ...form, key: e.target.value })}
            />
          </fieldset>
          <div className="text-center">
            <button className="btn btn-primary px-4">Proceed</button>
          </div>
        </form>
      </div>
    </div>
  );
}
