import React from "react";
import Axios from "axios";
import queryString from "querystring";
import { Redirect } from "react-router-dom";

import API from "../utils/api";
import UserContext from "../contexts/user";
import ErrorAlert from "../components/ErrorAlert";

export default function Login({ location }) {
  const [form, setForm] = React.useState({ username: "", password: "" });
  const [error, setError] = React.useState(null);
  const { setUser, isLoggedIn } = React.useContext(UserContext);
  const proceed = (e) => {
    e.preventDefault();
    Axios.post(API.users.login, form)
      .then((resp) => {
        const { accessToken, error } = resp.data;
        if (error) setError(error);
        if (accessToken) {
          localStorage.setItem("token", accessToken);
          setUser({ ...form });
        }
      })
      .catch(setError);
  };
  if (isLoggedIn) {
    const { r } = queryString.parse(location.search.replace("?", ""));
    return <Redirect to={r || "/"} />;
  }
  return (
    <div className="login-page my-auto">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <form onSubmit={proceed} className="bg-white p-4 shadow-sm">
            <h2 className="mb-3">Login</h2>
            <ErrorAlert error={error} setError={setError} />
            <fieldset className="form-group">
              <label>Username</label>
              <input
                type="text"
                className="form-control"
                value={form.username}
                onChange={(e) => setForm({ ...form, username: e.target.value })}
              />
            </fieldset>
            <fieldset className="form-group">
              <label>Password</label>
              <input
                type="password"
                className="form-control"
                value={form.password}
                onChange={(e) => setForm({ ...form, password: e.target.value })}
              />
            </fieldset>
            <div className="text-center">
              <button className="btn btn-primary px-4">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
