import React from "react";
import ReactPaginate from "react-paginate";
import classNames from "classnames";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
export default function Pagination({
  query,
  setQuery,
  total,
  small,
  containerClass,
}) {
  return (
    <nav className="mt-4">
      <ReactPaginate
        onPageChange={({ selected }) => {
          if (selected + 1 !== query.page) {
            setQuery({ ...query, page: selected + 1 });
          }
        }}
        containerClassName={classNames(
          "pagination",
          { "pagination-sm": small },
          containerClass ? containerClass : "justify-content-center"
        )}
        subContainerClassName={"page-item"}
        activeClassName={"active"}
        previousClassName={"page-item"}
        previousLabel={<AiOutlineDoubleLeft />}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLabel={<AiOutlineDoubleRight />}
        nextLinkClassName={"page-link"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        pageRangeDisplayed={5}
        marginPagesDisplayed={1}
        pageCount={total || 0}
        hrefBuilder={(e) => "?page=" + e}
        initialPage={query.page - 1}
      />
    </nav>
  );
}
