import React from "react";
import Axios from "axios";
import classNames from "classnames";
import { Link } from "react-router-dom";

import API from "../../utils/api";
import ErrorAlert from "../../components/ErrorAlert";

export default function MailgunAccounts() {
  const [working, setWorking] = React.useState(false);
  const [mailgunAccounts, setMailgunAccounts] = React.useState([]);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    setWorking(true);
    Axios.get(API.mailgunAccounts.get)
      .then((resp) => {
        const { error, mailgunAccounts } = resp.data;
        if (error) return setError(error);
        if (mailgunAccounts) setMailgunAccounts(mailgunAccounts);
      })
      .catch(setError)
      .finally(() => setWorking(false));
  }, []);

  const deleteMailgunAccount = (i) => {
    setWorking(true);
    Axios.delete(API.mailgunAccounts.delete(mailgunAccounts[i]._id))
      .then((resp) => {
        const { error, success } = resp.data;
        if (error) return setError(error);
        if (success) {
          const nState = [...mailgunAccounts];
          nState.splice(i, 1);
          setMailgunAccounts(nState);
        }
      })
      .catch(setError)
      .finally(() => setWorking(false));
  };

  return (
    <div className="mailgun-accounts m-4">
      <div className={classNames("shadow-sm", "p-3", "bg-white", { working })}>
        <div className="row mx-auto mb-3 align-items-center">
          <div className="col-md-6 mr-auto">
            <h1 className="m-0">Mailgun Accounts</h1>
          </div>
          <div className="col-md-5 text-right">
            <Link
              className="btn btn-outline-primary px-4 shadow-sm rounded-0"
              to="/mailgun-accounts/new"
            >
              New Account
            </Link>
          </div>
        </div>
        <ErrorAlert error={error} setError={setError} />
        {mailgunAccounts.length > 0 ? (
          <div className="data">
            <table className="table">
              <thead>
                <tr>
                  <th>Label</th>
                  <th>Info</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {mailgunAccounts.map(
                  ({ _id, username, key, publicKey, url, label }, i) => (
                    <tr key={_id}>
                      <td>{label}</td>
                      <td>
                        <ul>
                          <li>Username: {username}</li>
                          <li>Key: {key}</li>
                          <li>Public Key: {publicKey}</li>
                          <li>URL: {url}</li>
                        </ul>
                      </td>
                      <td>
                        <Link to={"/mailgun-accounts/" + _id + "/edit"}>
                          Edit
                        </Link>
                        {" - "}
                        <span
                          onClick={() => deleteMailgunAccount(i)}
                          className="text-danger pointer"
                        >
                          Delete
                        </span>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="text-center my-5">
            Mailgun accounts will appear here...
          </div>
        )}
      </div>
    </div>
  );
}
