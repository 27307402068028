import React from "react";
import Axios from "axios";
import classNames from "classnames";
import { Link } from "react-router-dom";

import API from "../../utils/api";
import ErrorAlert from "../../components/ErrorAlert";
import Paginate from "../../components/Pagination";

export default function Domains() {
  const [query, setQuery] = React.useState({ page: 1, name: "" });
  const [error, setError] = React.useState(null);
  const [domains, setDomains] = React.useState({ docs: [] });
  const [workingAt, setWorkingAt] = React.useState(-1);

  React.useEffect(() => {
    Axios.get(API.domains.browse(query))
      .then((resp) => {
        const { domains, error } = resp.data;
        if (error) return setError(error);
        if (domains) setDomains(domains);
      })
      .catch(setError);
  }, [query]);

  const cfFertig = (i) => {
    setWorkingAt(i);
    Axios.post(API.domains.fertig(domains.docs[i]._id), {})
      .then((resp) => {
        const { error, success } = resp.data;
        if (error) return setError(error);
        if (!success) setError("Failed!");
      })
      .catch(setError)
      .finally(() => setWorkingAt(-1));
  };

  const checkingIfActive = (i) => {
    setWorkingAt(i);
    Axios.post(API.domains.activationCheck(domains.docs[i]._id), {})
      .then((resp) => {
        const { error, success, domain } = resp.data;
        if (error) return setError(error);
        if (success && domain) {
          if (domain.cfInfo.status !== "active") {
            return setError(
              `Nameservers update pending. [${domain.cfInfo.name_servers.join(
                ", "
              )}]`
            );
          }
          const docs = [...domains.docs];
          docs[i] = domain;
          setDomains({ ...domains, docs });
        }
      })
      .catch(setError)
      .finally(() => setWorkingAt(-1));
  };

  const deleteDomain = (i) => {
    setWorkingAt(i);
    Axios.delete(API.domains.remove(domains.docs[i]._id))
      .then((resp) => {
        const { error, success } = resp.data;
        if (error) return setError(error);
        if (success) {
          const docs = [...domains.docs];
          docs.splice(i, 1);
          setDomains({ ...domains, docs });
        }
      })
      .catch(setError)
      .finally(() => setWorkingAt(-1));
  };

  return (
    <div className="domains m-4">
      <div className="shadow-sm p-3 bg-white">
        <div className="row mb-3 mx-auto" style={{ alignItems: "center" }}>
          <div className="col-md-2">
            <h1 className="h2 m-0">Domains</h1>
          </div>
          <div className="col-md-2 mr-auto">
            <Link
              to="/domains/add-new"
              className="btn btn-sm btn-outline-primary px-4"
            >
              Add New
            </Link>
          </div>
          <div className="col-md-5 ml-auto">
            <input
              type="text"
              className="form-control"
              value={query.name}
              onChange={(e) => setQuery({ ...query, name: e.target.value })}
              placeholder="Search domains"
            />
          </div>
        </div>
        <ErrorAlert error={error} setError={setError} />
        <div className="data">
          <table className="table">
            <thead>
              <tr>
                <th>Domain</th>
                <th>Account</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {domains.docs.map((domain, i) => (
                <tr
                  key={domain._id}
                  className={classNames("domain-row", {
                    working: i === workingAt,
                  })}
                >
                  <td>{domain.name}</td>
                  <td>
                    <small>{domain.account.email}</small>
                  </td>
                  <td className="actions">
                    {domain.cfInfo.status === "active" ? (
                      <>
                        <Link
                          to={"/domains/ssl/" + domain.name + "/" + domain._id}
                        >
                          SSL
                        </Link>
                        {" - "}
                        <span
                          onClick={() => cfFertig(i)}
                          className="pointer text-info"
                        >
                          CF Fertig
                        </span>
                        {" - "}
                        <Link to={"/domains/" + domain._id + "/firewall"}>
                          Firewall
                        </Link>
                        {" - "}
                        <Link
                          className="text-dark"
                          to={"/domains/" + domain._id + "/mailgun"}
                        >
                          Mailgun
                        </Link>
                        {" - "}
                        <Link to={"/domains/" + domain._id + "/dns"}>
                          Edit DNS
                        </Link>
                        {" - "}
                        <span
                          className="pointer text-danger"
                          onClick={() => deleteDomain(i)}
                        >
                          Delete
                        </span>
                      </>
                    ) : (
                      <div className="text-center">
                        Domain not Active.{" "}
                        <span
                          onClick={() => checkingIfActive(i)}
                          className="text-primary pointer"
                        >
                          Check
                        </span>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Paginate
          query={query}
          setQuery={setQuery}
          total={domains.totalPages}
          small
        />
      </div>
    </div>
  );
}
