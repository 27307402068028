import React from "react";

export default function TotalData({ data }) {
  const [show, setShow] = React.useState(false);
  const toggle = () => setShow(!show);
  return (
    <div className="total-data border p-3 shadow-sm">
      <div className="row" style={{ alignItems: "center" }}>
        <div className="col-md-10 mr-auto">
          <h4 className="h5 mb-0 pointer" onClick={toggle}>
            Show Total Data
          </h4>
        </div>
        <div className="col-md-2 ml-auto text-right">
          <button
            onClick={toggle}
            className="btn-sm btn btn-outline-primary px-3"
          >
            {show ? "Hide" : "Show"}
          </button>
        </div>
      </div>
      {show && <MapJSON data={data} />}
    </div>
  );
}

function MapJSON({ data, deep = 1 }) {
  return (
    <ul className={"map-json-" + deep}>
      {Object.keys(data).map((key) => (
        <li key={key}>
          <div>
            <b className="text-capitalize">{key}:</b>
            {"object" === typeof data[key] ? (
              <MapJSON data={data[key]} deep={deep + 1} />
            ) : (
              <span className="ml-1">{data[key]}</span>
            )}
          </div>
        </li>
      ))}
    </ul>
  );
}
