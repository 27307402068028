import React from "react";
import Axios from "axios";
import classNames from "classnames";

import API from "../../utils/api";
import ErrorAlert from "../../components/ErrorAlert";
import MailgunAddNewUser from "../../components/MailgunAddNewUser";
import AttachMailgunAccount from "../../components/AttachMailgunAccount";

export default function Mailgun({
  match: {
    params: { id },
  },
}) {
  const [working, setWorking] = React.useState(false);
  const [domain, setDomain] = React.useState(null);
  const [itr, setItr] = React.useState(1);
  const [dbDomain, setDbDomain] = React.useState({});
  const [error, setError] = React.useState(null);

  const destroyMG = () => {
    setWorking(true);
    Axios.delete(API.mailgun.delete(id))
      .then((resp) => {
        const { success, error } = resp.data;
        if (error) return setError(error);
        if (success) {
          setDomain(null);
        }
      })
      .catch(setError)
      .finally(() => setWorking(false));
  };

  const addDomainToMG = () => {
    setWorking(true);
    Axios.post(API.mailgun.new, { domain: id })
      .then((resp) => {
        const { mgResp, error, domain } = resp.data;
        if (error) return setError(error);
        if (mgResp) {
          setDomain(mgResp.domain);
        }
        if (domain) setDbDomain(domain);
      })
      .catch(setError)
      .finally(() => setWorking(false));
  };

  const verifyDomain = () => {
    setWorking(true);
    Axios.put(API.mailgun.verify(dbDomain._id), {})
      .then((resp) => {
        const { mgResp, error } = resp.data;
        if (error) return setError(error);
        if (mgResp) {
          setDomain(mgResp.domain);
        }
      })
      .catch(setError)
      .finally(() => setWorking(false));
  };

  React.useEffect(() => {
    setWorking(true);
    Axios.get(API.mailgun.get(id) + "?itr=" + itr)
      .then((resp) => {
        const { error, mgResp, domain } = resp.data;
        if (error) return setError(error);
        if (domain) setDbDomain(domain);
        if (mgResp) setDomain(mgResp);
      })
      .catch(setError)
      .finally(() => setWorking(false));
  }, [id, itr]);

  const iredClick = () => {
    setWorking(true);
    Axios.post(API.mailgun.ired(id), {})
      .then((resp) => {
        const { error, success } = resp.data;
        if (error) return setError(error);
        if (success) {
          setDbDomain({ ...dbDomain, iRedClickedAt: new Date() });
        }
      })
      .catch(setError)
      .finally(() => setWorking(false));
  };

  return (
    <div className="mailgun m-4">
      <div className={classNames("shadow-sm", "bg-white", "p-4", { working })}>
        <h1>Mailgun {dbDomain.name}</h1>
        <ErrorAlert error={error} setError={setError} />
        {working && (
          <div className="text-center loader py-3">
            <span className="spinner-border"></span>
            <div>
              <span>Working...</span>
            </div>
          </div>
        )}
        {
          <>
            <AttachMailgunAccount
              domainId={id}
              value={dbDomain?.mailgunAccount?._id}
              onAttached={(mailgunAccount) => {
                setDbDomain({ ...dbDomain, mailgunAccount });
                if (
                  typeof error === "string" &&
                  error.includes("mailgun account")
                ) {
                  setError(null);
                }
              }}
            />
          </>
        }
        {domain ? (
          <div className="my-3">
            <div className="text-center">
              {domain.state === "active" ? (
                <span>This domain is verified!</span>
              ) : (
                <span>This domain is still awaiting verification...</span>
              )}
            </div>
            <div className="row align-items-center">
              <div className="col-md-7">
                {Array.isArray(dbDomain.mgCreds) && (
                  <div className="copy">
                    <h4>Credentials</h4>
                    {dbDomain.mgCreds.map(({ login, password }, i) => (
                      <article
                        className="mb-3 border p-3 shadow-sm"
                        key={login}
                        data-login={login}
                      >
                        <h6>
                          {i + 1}# {login}
                        </h6>
                        <fieldset className="form-group">
                          <label>SMTP Login</label>
                          <input
                            type="text"
                            className="form-control"
                            value={login}
                            disabled
                          />
                        </fieldset>
                        <fieldset className="form-group">
                          <label>SMTP Password</label>
                          <input
                            type="text"
                            className="form-control"
                            value={password}
                            disabled
                          />
                        </fieldset>
                      </article>
                    ))}
                  </div>
                )}
              </div>
              <div className="col-md-5">
                {dbDomain._id && (
                  <div className="add-user border p-3">
                    <h4>Add New User</h4>
                    <MailgunAddNewUser
                      domainMongoId={dbDomain._id}
                      onAdded={(newCred) =>
                        setDbDomain({
                          ...dbDomain,
                          mgCreds: [
                            ...(Array.isArray(dbDomain.mgCreds)
                              ? dbDomain.mgCreds
                              : [dbDomain.mgCreds]),
                            newCred,
                          ],
                        })
                      }
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="my-4 pt-3 text-center">
              <button
                disabled={domain.state !== "unverified"}
                className="btn btn-outline-info px-3 btn-sm"
                onClick={verifyDomain}
              >
                Verify DNS
              </button>{" "}
              <button
                className="btn btn-outline-success px-3 btn-sm"
                onClick={() => setItr(itr + 1)}
              >
                Reload
              </button>{" "}
              <button
                className="btn btn-outline-danger px-3 btn-sm"
                onClick={destroyMG}
              >
                Destroy
              </button>{" "}
              <button
                className="btn btn-outline-info px-3 btn-sm"
                onClick={iredClick}
                disabled={dbDomain.iRedClickedAt}
              >
                Add iRedMail
              </button>
            </div>
          </div>
        ) : (
          <div className="text-center py-5">
            <button
              onClick={addDomainToMG}
              className="btn btn-outline-success px-3 btn-sm"
              disabled={!dbDomain.mailgunAccount}
            >
              Add Domain to Mailgun
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
