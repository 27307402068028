import React from "react";
import Axios from "axios";

import API from "../utils/api";
import ErrorAlert from "../components/ErrorAlert";

export default function Dashboard() {
  const [error, setError] = React.useState(null);
  const [counts, setCounts] = React.useState({});

  React.useEffect(() => {
    Axios.get(API.counts)
      .then((resp) => {
        const { counts, error } = resp.data;
        if (error) return setError(error);
        if (counts) setCounts(counts);
      })
      .catch(setError);
  }, []);

  return (
    <div className="dashboard m-4">
      <div className="p-3 shadow-sm bg-white">
        <h2>Dashboard</h2>
        <ErrorAlert error={error} setError={error} />
        <div className="data mt-3">
          <div className="row">
            {Object.keys(counts).map((key) => (
              <div key={key} className="col-md-3">
                <div className="shadow-sm p-3">
                  <h3 className="mb-1">{counts[key]}</h3>
                  <span className="text-capitalize">{key}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
