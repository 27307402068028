import React from "react";
import { Route, Redirect } from "react-router-dom";
import UserContext from "../contexts/user";
export default function PrivateRoute({ component: Component, ...rest }) {
  const { isLoggedIn } = React.useContext(UserContext);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (rest.page) props.page = rest.page;
        if (rest.active) props.active = rest.active;
        if (isLoggedIn) {
          return <Component {...props} />;
        } else {
          const { pathname, search } = rest.location;
          return <Redirect to={"/login?r=" + pathname + search} />;
        }
      }}
    />
  );
}
