import React from "react";
export default function ErrorAlert({ error, setError, style = {} }) {
  return (
    <>
      {error && (
        <div style={style} className="alert alert-danger alert-dismissible">
          <p className="m-0">
            {"string" === typeof error
              ? error
              : error.message || JSON.stringify(error)}
          </p>
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => setError(null)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )}
    </>
  );
}
