import React from "react";
import Axios from "axios";

import API from "../utils/api";
import ErrorAlert from "../components/ErrorAlert";

export default function GroupsDataList({ id }) {
  const [error, setError] = React.useState(null);
  const [groups, setGroups] = React.useState([]);

  React.useEffect(() => {
    Axios.get(API.loadBalancers.groups)
      .then((resp) => {
        const { error, groups } = resp.data;
        if (error) return setError(error);
        if (groups) setGroups(groups);
      })
      .catch(setError);
  }, []);

  return (
    <>
      <ErrorAlert error={error} setError={setError} />
      <datalist id={id}>
        {groups.map((g, i) => (
          <option key={i} value={g}>
            {g}
          </option>
        ))}
      </datalist>
    </>
  );
}
