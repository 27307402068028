import React from "react";
import Axios from "axios";
import classNames from "classnames";
import { Link, Redirect } from "react-router-dom";

import API from "../../utils/api";
import ErrorAlert from "../../components/ErrorAlert";

export default function Accounts() {
  const [accounts, setAccounts] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [refreshing, setRefrenshing] = React.useState(false);

  React.useEffect(() => {
    Axios.get(API.accounts.all)
      .then((resp) => {
        const { accounts, error } = resp.data;
        if (error) return setError(error);
        if (accounts) setAccounts(accounts);
      })
      .catch(setError);
  }, []);

  const deleteAccount = (i) => {
    Axios.delete(API.accounts.delete(accounts[i]._id))
      .then((resp) => {
        const { result, error } = resp.data;
        if (error) return setError(error);
        if (result && result.n) {
          const newAccounts = [...accounts];
          newAccounts.splice(i, 1);
          setAccounts(newAccounts);
        }
      })
      .catch(setError);
  };

  const refreshDomains = () => {
    setRefrenshing(true);
    Axios.post(API.accounts.refreshDomains, {})
      .then((resp) => {
        const { error, newTotal } = resp.data;
        if (error) return setError(error);
        if (newTotal) {
          setRefrenshing("done");
        }
      })
      .catch(setError);
  };

  if (refreshing === "done") {
    return <Redirect to={"/domains"} />;
  }

  return (
    <div className="accounts m-4">
      <div className="shadow-sm p-3 bg-white">
        <div className="row mx-auto mb-3" style={{ alignItems: "center" }}>
          <div className="col-md-4 mr-auto">
            <h1 className="m-0">Accounts</h1>
          </div>
          <div className="col-md-5 text-right">
            <button
              className={classNames(
                "btn",
                "mr-2",
                "btn-outline-info",
                "px-4",
                "shadow-sm",
                { disabled: refreshing === "loading" }
              )}
              onClick={refreshDomains}
            >
              {refreshing === "loading" ? "Working..." : "Refresh Domains"}
            </button>
            <Link className="btn btn-primary px-4 shadow-sm" to="/accounts/new">
              New Account
            </Link>
          </div>
        </div>
        <ErrorAlert error={error} setError={setError} />
        <div className="data">
          <table className="table">
            <thead>
              <tr>
                <th>Email</th>
                <th>Key</th>
                <th>Domains</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {accounts.map((account, i) => (
                <tr key={account._id}>
                  <td>{account.email}</td>
                  <td>{account.key}</td>
                  <td>{account.domainCount}</td>
                  <td>
                    <Link to={"/accounts/" + account._id}>Edit</Link>
                    {" - "}
                    <span
                      className="pointer text-danger"
                      onClick={() => deleteAccount(i)}
                    >
                      Delete
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
