import React from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { MdHttps } from "react-icons/md";
import format from "date-fns/format";

import API from "../utils/api";
import ErrorAlert from "../components/ErrorAlert";
import Paginate from "../components/Pagination";

export default function SSLCerts() {
  const [error, setError] = React.useState(null);
  const [sslCerts, setSslCerts] = React.useState({ docs: [] });
  const [query, setQuery] = React.useState({ page: 1 });
  const [refreshing, setRefreshing] = React.useState(false);

  React.useEffect(() => {
    Axios.get(API.ssl.certsList(query))
      .then((resp) => {
        const { error, sslCerts } = resp.data;
        if (error) return setError(error);
        if (sslCerts) setSslCerts(sslCerts);
      })
      .catch(setError);
  }, [query]);

  const refresh = (e) => {
    setRefreshing(true);
    e.preventDefault();
    Axios.post(API.ssl.refresh, {})
      .then((resp) => {
        const { error } = resp.data;
        if (error) return setError(error);
        setQuery({ lt: Date.now() });
      })
      .catch(setError)
      .finally(() => setRefreshing(false));
  };

  return (
    <div className="ssl-certs m-4">
      <div className="p-3 shadow-sm bg-white">
        <div className="row mb-3 mx-auto" style={{ alignItems: "center" }}>
          <div className="col-md-4">
            <h1 className="h2 m-0">SSL Certificates</h1>
          </div>
          <div className="col-md-2 mr-auto">
            <button
              onClick={refresh}
              className="btn btn-sm px-3 btn-outline-primary"
              disabled={refreshing}
            >
              Refresh
            </button>
          </div>
        </div>
        <ErrorAlert error={error} setError={setError} />
        {sslCerts.docs.length === 0 ? (
          <div className="row my-3 justify-content-center">
            <div className="col-md-7">
              <div className="p-3 border text-center">
                <div className="mb-2">
                  <MdHttps size={32} />
                </div>
                <p className="lead m-0">SSL Certificates will appear here.</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="data">
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Domain</th>
                  <th>Expiry</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {sslCerts.docs.map((sslCert) => (
                  <tr key={sslCert._id}>
                    <td>{sslCert._id}</td>
                    <td>
                      {sslCert.domain ? (
                        <span title={sslCert.domain._id}>
                          {sslCert.domain.name}
                        </span>
                      ) : (
                        <span>{sslCert.justName}</span>
                      )}
                    </td>
                    <td>
                      {sslCert.validTo
                        ? format(
                            new Date(sslCert.validTo),
                            `dd MMM yyyy, hh:mm a`
                          )
                        : "N/A"}
                    </td>
                    <td>
                      {sslCert.domain && (
                        <Link
                          to={`/domains/ssl/${sslCert.domain.name}/${sslCert.domain._id}`}
                        >
                          Generate New
                        </Link>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Paginate
              query={query}
              setQuery={setQuery}
              total={sslCerts.totalPages}
              small
            />
          </div>
        )}
      </div>
    </div>
  );
}
