import React from "react";
import Axios from "axios";
import { Redirect } from "react-router-dom";

import API from "../../utils/api";
import ErrorAlert from "../../components/ErrorAlert";
import GroupsDataList from "../../components/GroupsDataList";

export default function AddNewLoadBalancer() {
  const [error, setError] = React.useState(null);
  const [form, setForm] = React.useState({ ip1: "", ip2: "", group: "" });
  const [created, setCreated] = React.useState(false);

  const proceed = (e) => {
    e.preventDefault();
    Axios.post(API.loadBalancers.new, form)
      .then((resp) => {
        const { error, loadBalancer } = resp.data;
        if (error) return setError(error);
        if (loadBalancer) setCreated(true);
      })
      .catch(setError);
  };

  if (created) {
    return <Redirect to="/load-balancers" />;
  }

  return (
    <div className="add-new-load-balancer m-4">
      <div className="p-3 bg-white shadow-sm">
        <h1>Add New Load Balancer</h1>
        <div className="row justify-content-center mt-3">
          <div className="col-md-7">
            <form onSubmit={proceed} className="p-3 border">
              <ErrorAlert error={error} setError={setError} />
              <fieldset className="form-group">
                <label>IP 1</label>
                <input
                  type="text"
                  className="form-control"
                  value={form.ip1}
                  onChange={(e) => setForm({ ...form, ip1: e.target.value })}
                />
              </fieldset>
              <fieldset className="form-group">
                <label>IP 2</label>
                <input
                  type="text"
                  className="form-control"
                  value={form.ip2}
                  onChange={(e) => setForm({ ...form, ip2: e.target.value })}
                />
              </fieldset>
              <fieldset className="form-group">
                <label>Group</label>
                <input
                  type="text"
                  className="form-control"
                  value={form.group}
                  onChange={(e) => setForm({ ...form, group: e.target.value })}
                  list="lb-groups-a"
                />
                <GroupsDataList id="lb-groups-a" />
              </fieldset>
              <div className="text-center">
                <button className="btn-primary btn px-4">Proceed</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
