export const actions = {
  block: "Block",
  challenge: "Challenge (Captcha)",
  js_challenge: "JS Challenge",
  allow: "Allow",
  log: "Log",
  bypass: "Bypass",
  rewrite: "Rewrite",
};

export const settingsDetails = {
  browser_check: {
    label: "Browser Integrity Check",
    description:
      "Evaluate HTTP headers from your visitors browser for threats. If a threat is found a block page will be delivered.",
  },
  challenge_ttl: {
    label: "Challenge Passage",
    description:
      "Specify the length of time that a visitor, who has successfully completed a Captcha or JavaScript Challenge, can access your website. When the configured timeout expires, the visitor will be issued a new challenge.",
  },
  privacy_pass: {
    label: "Privacy Pass Support",
    description:
      "Privacy Pass is a browser extension developed by the Privacy Pass Team to improve the browsing experience for your visitors. Enabling Privacy Pass will reduce the number of CAPTCHAs shown to your visitors.",
  },
  security_level: {
    label: "Security Level",
    description:
      "Adjust your website’s Security Level to determine which visitors will receive a challenge page.",
  },
};

export const challengeTtlOptions = [
  300,
  900,
  1800,
  2700,
  3600,
  7200,
  10800,
  14400,
  28800,
  57600,
  86400,
  604800,
  2592000,
  31536000,
];

export const securityLevels = [
  "off",
  "essentially_off",
  "low",
  "medium",
  "high",
  "under_attack",
];
