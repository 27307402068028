import React from "react";
import Axios from "axios";

import API from "../../utils/api";
import ErrorAlert from "../../components/ErrorAlert";
import GroupsDataList from "../../components/GroupsDataList";

export default function EditLoadBalancer({
  match: {
    params: { id },
  },
}) {
  const [error, setError] = React.useState(null);
  const [form, setForm] = React.useState({ ip1: "", ip2: "", group: "" });
  const [updated, setUpdated] = React.useState(false);

  React.useEffect(() => {
    Axios.get(API.loadBalancers.single(id))
      .then((resp) => {
        const { error, loadBalancer } = resp.data;
        if (error) return setError(error);
        if (loadBalancer) {
          setForm({
            ip1: loadBalancer.ips[0],
            ip2: loadBalancer.ips[1],
            group: loadBalancer.group,
            currentIp: loadBalancer.currentIp,
          });
        }
      })
      .catch(setError);
  }, [id]);

  const proceed = (e) => {
    e.preventDefault();
    Axios.post(API.loadBalancers.update(id), form)
      .then((resp) => {
        const { error, success } = resp.data;
        if (error) return setError(error);
        if (success) setUpdated(true);
      })
      .catch(setError);
  };

  return (
    <div className="edit-load-balancer m-4">
      <div className="p-3 bg-white shadow-sm">
        <h1>Editing Load Balancer</h1>
        <div className="row justify-content-center mt-3">
          <div className="col-md-7">
            <form onSubmit={proceed} className="p-3 border">
              <ErrorAlert error={error} setError={setError} />
              {updated && <div className="alert alert-success">Updated!</div>}
              <fieldset className="form-group">
                <label>IP 1</label>
                <input
                  type="text"
                  className="form-control"
                  value={form.ip1}
                  onChange={(e) => setForm({ ...form, ip1: e.target.value })}
                />
              </fieldset>
              <fieldset className="form-group">
                <label>IP 2</label>
                <input
                  type="text"
                  className="form-control"
                  value={form.ip2}
                  onChange={(e) => setForm({ ...form, ip2: e.target.value })}
                />
              </fieldset>
              <fieldset className="form-group">
                <label>Group</label>
                <input
                  type="text"
                  className="form-control"
                  value={form.group}
                  onChange={(e) => setForm({ ...form, group: e.target.value })}
                  list="lb-groups-e"
                />
                <GroupsDataList id="lb-groups-e" />
              </fieldset>
              <fieldset className="form-group">
                <label>Current IP</label>
                <select
                  className="custom-select"
                  value={form.currentIp}
                  onChange={(e) =>
                    setForm({ ...form, currentIp: e.target.value })
                  }
                >
                  <option value="0">1st</option>
                  <option value="1">2nd</option>
                </select>
              </fieldset>
              <div className="text-center">
                <button className="btn-primary btn px-4">Proceed</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
