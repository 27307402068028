import React from "react";
import Axios from "axios";
import subHours from "date-fns/subHours";
import format from "date-fns/format";
import classNames from "classnames";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import DatePicker from "react-datepicker";

import API from "../utils/api";
import ErrorAlert from "../components/ErrorAlert";
import TotalData from "../components/TotalData";

const myFormatStyle = "dd.MM.yyyy hh:mma";

export default function StatisticsGraph({ domain }) {
  const today = new Date();
  const [error, setError] = React.useState(null);
  const [stats, setStats] = React.useState(null);
  const [query, setQuery] = React.useState({
    since: subHours(today, 6),
    until: today,
  });
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    Axios.get(
      API.statistics.get(domain._id, {
        since: query.since.toISOString(),
        until: query.until.toISOString(),
      })
    )
      .then((resp) => {
        const { error, data, errors } = resp.data;
        if (error || errors) return setError(error || errors[0]);
        if (data && data.viewer.zones) {
          setStats(data.viewer.zones[0].httpRequests1hGroups);
        }
      })
      .catch(setError)
      .finally(() => setLoading(false));
  }, [query, domain._id]);

  return (
    <div className={classNames("border", "rounded", "p-3", { loading })}>
      {loading && (
        <div className="loader">
          <span className="spinner-grow"></span>
        </div>
      )}
      <ErrorAlert error={error} setError={setError} />
      <div className="stats-container">
        <p>
          Cloudflare <b>{domain.name}'s</b> http requests.
        </p>
        {stats ? (
          <div className="chart container">
            <div className="meta mb-3 row" style={{ alignItems: "center" }}>
              <div className="col-md-6">
                <p className="m-0">
                  From <b>{format(query.since, myFormatStyle)}</b> to{" "}
                  <b>{format(query.until, myFormatStyle)}</b>
                </p>
              </div>
              <div className="col-md-3">
                <label>From</label>
                <DatePicker
                  selected={query.since}
                  onChange={(since) => setQuery({ ...query, since })}
                  showTimeSelect
                  dateFormat="Pp"
                />
              </div>
              <div className="col-md-3">
                <label>To</label>
                <DatePicker
                  selected={query.until}
                  maxDate={today}
                  minDate={query.since}
                  onChange={(until) => setQuery({ ...query, until })}
                  showTimeSelect
                  dateFormat="Pp"
                />
              </div>
            </div>
            <LineChart
              style={{ margin: "0 auto" }}
              width={900}
              height={550}
              data={stats}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis
                dataKey="dimensions.datetime"
                tickFormatter={(time) =>
                  format(new Date(time), "E dd MMM, hh a")
                }
              />
              <YAxis />
              <Line
                name="Requests"
                type="monotone"
                dataKey="sum.requests"
                stroke="green"
              />
              <Line name="Page Views" type="monotone" dataKey="sum.pageViews" />
              <Tooltip />
              <CartesianGrid strokeDasharray="5" />
              <Legend />
            </LineChart>
            <div className="mt-3 totals">
              <TotalData data={stats} />
            </div>
          </div>
        ) : (
          <div className="text-center py-5">Stats will appear here.</div>
        )}
      </div>
    </div>
  );
}
