import React from "react";
import Axios from "axios";
import classNames from "classnames";

import API from "../../utils/api";
import ErrorAlert from "../ErrorAlert";
import CreateRule from "./CreateRule";
import EditRule from "./EditRule";
import { actions } from "./commons";
import { longDate } from "../../utils";

export default function Rules({ domainId }) {
  const [error, setError] = React.useState(null);
  const [rules, setRules] = React.useState([]);
  const [creating, setCreating] = React.useState(false);
  const [working, setWorking] = React.useState(-1);
  const [editing, setEditing] = React.useState(-1);

  React.useEffect(() => {
    let mounted = true;
    Axios.get(API.firewall.rules.all(domainId))
      .then((resp) => {
        if (!mounted) return;
        const { error, errors, success, result } = resp.data;
        if (error || errors.length > 0) return setError(error || errors);
        if (success && result) {
          setRules(result);
        }
      })
      .catch(setError);
    return () => (mounted = false);
  }, [domainId]);

  const deleteRule = (i) => {
    setWorking(i);
    Axios.delete(
      API.firewall.rules.delete(domainId, rules[i].id, {
        filterId: rules[i].filter.id,
      })
    )
      .then((resp) => {
        const { success, error, errors } = resp.data;
        if (error || errors.length > 0) return setError(error || errors[0]);
        if (success) {
          const list = [...rules];
          list.splice(i, 1);
          setRules(list);
        }
      })
      .catch(setError)
      .finally(() => setWorking(-1));
  };

  const toggleStatus = (i) => {
    setWorking(i);
    Axios.post(API.firewall.rules.update(domainId, rules[i].id), {
      ...rules[i],
      paused: !rules[i].paused,
    })
      .then((resp) => {
        const { result, success, error, errors } = resp.data;
        if (error || errors.length > 0) return setError(error || errors[0]);
        if (success && result) {
          const list = [...rules];
          list[i] = result;
          setRules(list);
        }
      })
      .catch(setError)
      .finally(() => setWorking(-1));
  };

  return (
    <div className="firewall-rules py-2">
      <div className="clearfix mb-2">
        <div className="float-left">
          <h2 className="h3">Firewall Rules</h2>
        </div>
        <div className="float-right">
          <button
            onClick={() => setCreating(true)}
            className="btn btn-outline-primary px-3"
          >
            Create
          </button>
        </div>
      </div>
      <ErrorAlert error={error} setError={setError} />
      {rules.length === 0 ? (
        <div className="text-center py-3 border">
          <p className="m-0">Firewall Rules will appear here.</p>
        </div>
      ) : (
        <table className="table">
          <thead>
            <tr>
              <th>Action</th>
              <th>Description</th>
              <th>Status</th>
              <th>Last Updated</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {rules.map((rule, i) => (
              <tr
                key={rule.id}
                className={classNames({ working: working === i })}
              >
                <td>{actions[rule.action]}</td>
                <td>{rule.description}</td>
                <td>
                  {rule.paused ? (
                    <span className="text-danger">Inactive</span>
                  ) : (
                    <span className="text-success">Active</span>
                  )}
                </td>
                <td>{longDate(rule.modified_on)}</td>
                <td className="actions">
                  <span
                    onClick={() => toggleStatus(i)}
                    className="text-info pointer"
                  >
                    Toggle {rule.paused ? "On" : "Off"}
                  </span>
                  {" - "}
                  <span
                    onClick={() => setEditing(i)}
                    className="text-primary pointer"
                  >
                    Edit
                  </span>
                  {" - "}
                  <span
                    onClick={() => deleteRule(i)}
                    className="text-danger pointer"
                  >
                    Delete
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {creating && (
        <CreateRule
          domain={domainId}
          onCreated={(rule) => setRules([...rules, rule])}
          close={() => setCreating(false)}
        />
      )}
      {rules[editing] && (
        <EditRule
          domain={domainId}
          rule={rules[editing]}
          onEdited={(rule) => {
            const list = [...rules];
            list[editing] = rule;
            setRules(list);
          }}
          close={() => setEditing(-1)}
        />
      )}
    </div>
  );
}
