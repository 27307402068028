import React from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

import API from "../../utils/api";
import ErrorAlert from "../../components/ErrorAlert";

const Input = ({ name, placeholder, form, setForm, type = "text" }) => (
  <input
    type={type}
    className="form-control"
    placeholder={placeholder}
    name={name}
    value={form[name]}
    onChange={(e) => setForm({ ...form, [name]: e.target.value })}
  />
);

export default function AddNewMailgunAccount() {
  const history = useHistory();
  const [form, setForm] = React.useState({
    label: "",
    username: "api",
    key: "",
    publicKey: "",
    url: "https://api.eu.mailgun.net",
  });
  const [error, setError] = React.useState(null);
  const [working, setWorking] = React.useState(false);

  const proceed = (e) => {
    e.preventDefault();
    setWorking(true);
    Axios.post(API.mailgunAccounts.add, form)
      .then((resp) => {
        const { error, mailgunAccount } = resp.data;
        if (error) return setError(error);
        if (mailgunAccount) {
          history.push("/mailgun-accounts");
        }
      })
      .catch(setError)
      .finally(() => setWorking(false));
  };

  return (
    <div className="new-account m-4">
      <div className={classNames("shadow-sm", "p-3", "bg-white", { working })}>
        <h1>New Mailgun Account</h1>
        <ErrorAlert error={error} setError={setError} />
        <div className="row justify-content-center my-4">
          <div className="col-md-7">
            <form onSubmit={proceed} className="form">
              <fieldset className="form-group">
                <label>Label</label>
                <Input name="label" form={form} setForm={setForm} />
              </fieldset>
              <fieldset className="form-group">
                <label>Username</label>
                <Input name="username" form={form} setForm={setForm} />
              </fieldset>
              <fieldset className="form-group">
                <label>Key</label>
                <Input name="key" form={form} setForm={setForm} />
              </fieldset>
              <fieldset className="form-group">
                <label>Public Key</label>
                <Input name="publicKey" form={form} setForm={setForm} />
              </fieldset>
              <fieldset className="form-group">
                <label>URL</label>
                <Input name="url" form={form} setForm={setForm} />
              </fieldset>
              <div className="text-center">
                <button className="btn btn-outline-primary px-4 rounded-0 shadow-sm">
                  Proceed
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
