import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import Rules from "../../components/firewall/Rules";
import FirewallSettings from "../../components/firewall/FirewallSettings";

export default function Firewall({
  match: {
    params: { id },
  },
}) {
  return (
    <div className="firewall m-4">
      <div className="shadow-sm bg-white p-3">
        <h1>Firewall</h1>
        <div className="container my-2">
          <Tabs>
            <TabList>
              <Tab>Firewall Rules</Tab>
              <Tab>Settings</Tab>
            </TabList>
            <TabPanel>
              <div className="rules-tab">
                <Rules domainId={id} />
              </div>
            </TabPanel>
            <TabPanel>
              <div className="settings-tab">
                <FirewallSettings domainId={id} />
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </div>
  );
}
