import React from "react";
import Axios from "axios";
import classNames from "classnames";

import API from "../utils/api";
import ErrorAlert from "./ErrorAlert";

export default function AttachMailgunAccount({ domainId, value, onAttached }) {
  const [mailgunAccounts, setMailgunAccounts] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [working, setWorking] = React.useState(false);
  const [selected, setSelected] = React.useState("");

  React.useEffect(() => {
    setWorking(true);
    Axios.get(API.mailgunAccounts.get)
      .then((resp) => {
        const { error, mailgunAccounts } = resp.data;
        if (error) return setError(error);
        if (mailgunAccounts) setMailgunAccounts(mailgunAccounts);
      })
      .catch(setError)
      .finally(() => setWorking(false));
  }, []);

  React.useEffect(() => {
    if (value) {
      setSelected(value);
    }
  }, [value]);

  const proceed = (e) => {
    e.preventDefault();
    setWorking(true);
    Axios.patch(API.mailgun.attachMailgunAccount(domainId), {
      mailgunAccount: selected,
    })
      .then((resp) => {
        const { error, success } = resp.data;
        if (error) return setError(error);
        if (success) {
          onAttached(mailgunAccounts.find(({ _id }) => selected === _id));
        }
      })
      .catch(setError)
      .finally(() => setWorking(false));
  };

  return (
    <form
      onSubmit={proceed}
      className={classNames("form", "attach-mailgun-account", { working })}
    >
      <ErrorAlert error={error} setError={setError} />
      <fieldset className="form-group row align-items-center">
        <label className="col-md-2 col-form-label">Mailgun Account</label>
        <div className="col-md-8">
          <select
            value={selected}
            onChange={(e) => setSelected(e.target.value)}
            className="custom-select"
            disabled={working}
          >
            <option value="">Please select a mailgun account</option>
            {mailgunAccounts.map(({ label, _id }) => (
              <option key={_id} value={_id}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-2">
          <button className="btn btn-outline-primary w-100 shadow-sm rounded-0">
            Attach
          </button>
        </div>
      </fieldset>
    </form>
  );
}
