import React from "react";
import Axios from "axios";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { CopyToClipboard } from "react-copy-to-clipboard";
import classNames from "classnames";

import API from "../../utils/api";
import ErrorAlert from "../../components/ErrorAlert";

export default function DomainSSL({
  match: {
    params: { id, domain },
  },
}) {
  const [error, setError] = React.useState(null);
  const [zip, setZip] = React.useState(null);
  const [exists, setExists] = React.useState(false);
  const [working, setWorking] = React.useState(false);

  React.useEffect(() => {
    Axios.get(API.ssl.exists(domain + ".zip"))
      .then((resp) => {
        const { error, exists } = resp.data;
        if (error) return setError(error);
        if (exists) setExists(exists);
      })
      .catch(setError);
  }, [domain]);

  const generateSSL = (e) => {
    e.preventDefault();
    setWorking(true);
    Axios.post(API.ssl.generate(id), {})
      .then((resp) => {
        const { error, zip } = resp.data;
        if (error) return setError(error);
        if (zip) setZip(zip);
      })
      .catch(setError)
      .finally(() => setWorking(false));
  };

  return (
    <div className="ssl m-4">
      <div className="shadow-sm p-3 bg-white">
        <h1>SSL Generation For {domain}</h1>
        <ErrorAlert error={error} setError={setError} />
        {exists && (
          <div className="rounded shadow-sm existence-notice mb-4 border p-3">
            <p className="m-0">
              <AiOutlineInfoCircle size={30} className="mr-3 text-secondary" />{" "}
              A certificate zip already exists for <b>{domain}</b> already.{" "}
              <a
                style={{ fontWeight: 500, borderBottom: "1px solid" }}
                href={API.ssl.download(domain + ".zip")}
                target="_blank"
                rel="noreferrer"
              >
                Download Now
              </a>
              .
            </p>
          </div>
        )}
        <div className="justify-content-center row my-4">
          {zip ? (
            <div className="col-md-8">
              <h3>Certificate generated!</h3>
              <div className="text-center">
                <a
                  href={API.ssl.download(zip + ".zip")}
                  className="btn btn-primary"
                  target="_blank"
                  rel="noreferrer"
                >
                  Download
                </a>
              </div>
            </div>
          ) : (
            <div
              className={classNames(
                "generator",
                "col-md-8",
                "py-3",
                {
                  working,
                },
                "border"
              )}
            >
              <h3>Generate a New Certificate</h3>
              <div className="text-center mt-5 py-3">
                <button
                  onClick={generateSSL}
                  className="btn btn-outline-primary px-5 shadow-sm"
                >
                  Generate New
                </button>
              </div>
              {working && (
                <div className="loader">
                  <span className="spinner-border"></span>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="mt-3">
          {(exists || zip) && (
            <div className="copy p-2 bg-light border">
              <CopyToClipboard
                text={`mkdir ${domain} && cd ${domain} && wget ${API.ssl.download(
                  domain + ".zip"
                )} && unzip ${domain}.zip && rm ${domain}.zip && cd ..`}
              >
                <span title="Click to copy">
                  mkdir {domain} && cd {domain} && wget{" "}
                  {API.ssl.download(domain + ".zip")} && unzip {domain}.zip &&
                  rm {domain}.zip
                </span>
              </CopyToClipboard>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
