import queryString from "querystring";

import { ReactIsInDevelomentMode } from ".";
let base = "http://localhost:2000";
if (!ReactIsInDevelomentMode()) {
  base = window.location.origin;
}
base = base + "/api";

const API = {
  users: {
    login: base + "/user/login",
    me: base + "/user/me",
  },
  counts: base + "/counts",
  accounts: {
    all: base + "/accounts",
    get: (id) => base + "/accounts/" + id,
    new: base + "/accounts/new",
    update: (id) => base + "/accounts/" + id,
    delete: (id) => base + "/accounts/" + id,
    refreshDomains: base + "/accounts/refresh-domains",
  },
  ssl: {
    exists: (name) => base + "/ssl/exists/" + name,
    download: (name) => base + "/ssl/certs/" + name,
    generate: (domain) => base + "/ssl/" + domain + "/ssl",
    certsList: (q) => base + "/ssl/certs-list?" + queryString.stringify(q),
    refresh: base + "/ssl/do-map",
  },
  domains: {
    addNew: (account) => base + "/domains/" + account + "/add",
    activationCheck: (domain) =>
      base + "/domains/" + domain + "/activation-check",
    remove: (domain) => base + "/domains/" + domain,
    browse: (q) => base + "/domains?" + queryString.stringify(q),
    quickList: base + "/domains/quick-list",
    single: (domain) => base + "/domains/" + domain,
    delete: (domain, record) => base + "/domains/" + domain + "/dns/" + record,
    getDNS: (domain, q) =>
      base + "/domains/" + domain + "/dns?" + queryString.stringify(q),
    fertig: (domain) => base + "/domains/" + domain + "/cf-fertig",
    newRecord: (domain) => base + "/domains/" + domain + "/dns/new",
    updateDNS: (domain, record) =>
      base + "/domains/" + domain + "/dns/update-record/" + record,
    deleteAllRecords: (domain) => base + "/domains/" + domain + "/all-dns",
  },
  mailgun: {
    get: (domain) => base + "/mailgun/" + domain,
    doDNS: (domain) => base + "/mailgun/do-dns/" + domain,
    createDomain: (domain) => base + "/mailgun/create-domain/" + domain,
    new: base + "/mailgun/new",
    delete: (domain) => base + "/mailgun/" + domain,
    getCredentials: (domain) => base + "/mailgun/" + domain + "/credentials",
    verify: (domain) => base + "/mailgun/" + domain + "/verify",
    ired: (domain) => base + "/mailgun/" + domain + "/ired-click",
    addUser: base + "/mailgun/add-user",
    attachMailgunAccount: (domain) =>
      base + "/mailgun/" + domain + "/attach-mailgun-account",
  },
  mailgunAccounts: {
    get: base + "/mailgun-accounts",
    single: (id) => base + "/mailgun-accounts/" + id + "/get",
    add: base + "/mailgun-accounts/add",
    update: (id) => base + "/mailgun-accounts/" + id + "/update",
    delete: (id) => base + "/mailgun-accounts/" + id + "/delete",
  },
  loadBalancers: {
    groups: base + "/load-balancers/groups",
    all: (q) => base + "/load-balancers?" + queryString.stringify(q),
    single: (id) => base + "/load-balancers/" + id,
    new: base + "/load-balancers/new",
    update: (id) => base + "/load-balancers/" + id + "/update",
    delete: (id) => base + "/load-balancers/" + id,
  },
  firewall: {
    rules: {
      all: (domain) => base + "/domain-firewall/" + domain + "/rules",
      create: (domain) => base + "/domain-firewall/" + domain + "/rules/create",
      update: (domain, id) =>
        base + "/domain-firewall/" + domain + "/rules/" + id,
      delete: (domain, id, query = {}) =>
        base +
        "/domain-firewall/" +
        domain +
        "/rules/" +
        id +
        "?" +
        queryString.stringify(query),
    },
    filters: {
      create: (domain) =>
        base + "/domain-firewall/" + domain + "/filters/create",
    },
    settings: {
      get: (domain) => base + "/domain-firewall/" + domain + "/settings",
      save: (domain, setting) =>
        base + "/domain-firewall/" + domain + "/settings/" + setting,
    },
  },
  statistics: {
    get: (domain, qs) =>
      base + "/statistics/" + domain + "/v2?" + queryString.stringify(qs),
  },
};

export default API;
