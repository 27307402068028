import React from "react";
import Axios from "axios";
import classNames from "classnames";

import API from "../utils/api";
import ErrorAlert from "./ErrorAlert";

export default function MailgunAddNewUser({ domainMongoId, onAdded }) {
  const [login, setLogin] = React.useState("");
  const [irName, setIrName] = React.useState("");
  const [error, setError] = React.useState(null);
  const [working, setWorking] = React.useState(false);

  const proceed = (e) => {
    e.preventDefault();
    setWorking(true);
    Axios.post(API.mailgun.addUser, { irName, domain: domainMongoId, login })
      .then((resp) => {
        const { error, newCred } = resp.data;
        if (error) return setError(error);
        if (newCred) {
          onAdded(newCred);
          setLogin("");
          setIrName("");
        }
      })
      .catch(setError)
      .finally(() => setWorking(false));
  };

  return (
    <form onSubmit={proceed} className={classNames({ working })}>
      <ErrorAlert error={error} setError={setError} />
      <fieldset className="form-group">
        <label>Login</label>
        <input
          type="text"
          placeholder="someuser"
          value={login}
          onChange={(e) => setLogin(e.target.value)}
          className="form-control"
          required
        />
      </fieldset>
      <fieldset className="form-group">
        <label>iRedMail Display Name</label>
        <input
          type="text"
          className="form-control"
          placeholder="Some User (Optional)"
          value={irName}
          onChange={(e) => setIrName(e.target.value)}
        />
      </fieldset>
      <div className="text-center">
        <button className="btn btn-primary px-4">Add User</button>
      </div>
      {working && (
        <div className="loader">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </form>
  );
}
