import React from "react";
import Axios from "axios";
import { Redirect } from "react-router-dom";

import API from "../../utils/api";
import ErrorAlert from "../../components/ErrorAlert";

export default function AddNewDomain() {
  const [form, setForm] = React.useState({
    name: "",
    jump_start: false,
    type: "",
  });
  const [account, setAccount] = React.useState("");
  const [accounts, setAccounts] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [done, setDone] = React.useState(false);
  const [domain, setDomain] = React.useState(null);

  React.useEffect(() => {
    Axios.get(API.accounts.all)
      .then((resp) => {
        const { error, accounts } = resp.data;
        if (error) return setError(error);
        if (accounts) setAccounts(accounts);
      })
      .catch(setError);
  }, []);

  const proceed = (e) => {
    e.preventDefault();
    Axios.post(API.domains.addNew(account), form)
      .then((resp) => {
        const { domain, error } = resp.data;
        if (error) return setError(error);
        if (domain) setDomain(domain);
      })
      .catch(setError);
  };

  if (done) {
    return <Redirect to="/domains" />;
  }

  return (
    <div className="add-new-domain m-4">
      <div className="shadow-sm p-3 bg-white">
        <h1 className="h2">Adding New Domain</h1>
        <ErrorAlert error={error} setError={setError} />
        <div className="row justify-content-center my-3">
          <div className="col-md-8 border p-3 rounded">
            {domain ? (
              <div className="name-servers">
                <p>Change the nameservers:</p>
                <textarea
                  className="form-control disabled"
                  value={domain.cfInfo.name_servers.join("\n")}
                  disabled
                />
                <hr />
                <button
                  className="btn btn-outline-primary btn-sm"
                  onClick={() => setDone(true)}
                >
                  Done
                </button>
              </div>
            ) : (
              <>
                <div className="select-account">
                  <label>Account</label>
                  <select
                    value={account}
                    onChange={(e) => setAccount(e.target.value)}
                    className="custom-select"
                  >
                    <option value="">Select an Account</option>
                    {accounts.map((acc) => (
                      <option key={acc._id} value={acc._id}>
                        {acc.email}
                      </option>
                    ))}
                  </select>
                </div>
                {account && (
                  <form onSubmit={proceed} className="form mt-3">
                    <fieldset className="form-group">
                      <label>Domain Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={form.name}
                        onChange={(e) =>
                          setForm({ ...form, name: e.target.value })
                        }
                        placeholder="example.com"
                        required
                      />
                    </fieldset>
                    <fieldset className="form-group">
                      <label>Type (Optional)</label>
                      <select
                        value={form.type}
                        onChange={(e) =>
                          setForm({ ...form, type: e.target.value })
                        }
                        className="custom-select"
                      >
                        <option value="">Select Type</option>
                        <option value="full">Full</option>
                        <option value="partial">Partial</option>
                      </select>
                    </fieldset>
                    <fieldset className="form-group text-center">
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="jumpStart"
                          value={form.jump_start}
                          onChange={() =>
                            setForm({
                              ...form,
                              jump_start: form.jump_start ? false : true,
                            })
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="jumpStart"
                        >
                          Jump Start
                        </label>
                      </div>
                    </fieldset>
                    <hr />
                    <div className="text-center">
                      <button className="btn btn-sm btn-outline-primary px-4">
                        Proceed
                      </button>
                    </div>
                  </form>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
