import React from "react";

export function ReactIsInDevelomentMode() {
  return "_self" in React.createElement("div");
}

export function longDate(str) {
  return new Date(str).toLocaleDateString("de", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
}
