import React from "react";
import Axios from "axios";
import classNames from "classnames";
import Modal from "react-modal";

import API from "../../utils/api";
import ErrorAlert from "../../components/ErrorAlert";
import { actions } from "./commons";

export default function CreateRule({ domain, onCreated, close }) {
  const [error, setError] = React.useState(null);
  const [working, setWorking] = React.useState(false);
  const [form, setForm] = React.useState({
    action: "block",
    filter: {
      expression: "",
    },
    products: "",
    priority: "",
    paused: true,
    ref: "",
    action_parameters: "",
    description: "",
  });

  const proceed = (e) => {
    e.preventDefault();
    setWorking(true);
    Axios.post(API.firewall.rules.create(domain), form)
      .then((resp) => {
        const { error, errors, success, result } = resp.data;
        if (error || errors.length > 0) return setError(error || errors[0]);
        if (success && result[0]) {
          onCreated(result[0]);
          close();
        }
      })
      .catch(setError)
      .finally(() => (error ? setWorking(false) : null));
  };

  const styles = {
    overlay: {
      backgroundColor: "rgb(15 15 15 / 75%)",
      zIndex: 999,
    },
  };

  return (
    <Modal isOpen={true} style={styles} contentLabel="Create Rule">
      <h4>Create Rule</h4>
      <ErrorAlert error={error} setError={setError} />
      <form onSubmit={proceed} className="form container mt-3">
        <fieldset className="form-group">
          <label>Description</label>
          <input
            type="text"
            className="form-control"
            value={form.description}
            onChange={(e) => setForm({ ...form, description: e.target.value })}
          />
        </fieldset>
        <fieldset className="form-group">
          <label>Filter Expression</label>
          <textarea
            value={form.filter.expression}
            onChange={(e) =>
              setForm({
                ...form,
                filter: { ...form.filter, expression: e.target.value },
              })
            }
            rows="5"
            className="form-control"
            required
          />
        </fieldset>
        <fieldset className="form-group">
          <label>Action</label>
          <select
            value={form.action}
            onChange={(e) => setForm({ ...form, action: e.target.value })}
            className="custom-select"
            required
          >
            <option value="" disabled>
              Select One
            </option>
            {Object.keys(actions).map((k, i) => (
              <option key={i} value={k}>
                {actions[k]}
              </option>
            ))}
          </select>
        </fieldset>
        <fieldset className="form-group">
          <label>Paused</label>
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="cr_paused"
              checked={form.paused}
              onChange={() => setForm({ ...form, paused: !form.paused })}
            />
            <label className="custom-control-label" htmlFor="cr_paused">
              Paused
            </label>
          </div>
        </fieldset>
        <div className="text-right">
          <button
            onClick={close}
            type="button"
            className="btn btn-secondary px-3 mr-2"
          >
            Cancel
          </button>
          <button
            className={classNames("btn", "btn-primary", "px-3", {
              disabled: working,
            })}
            disabled={working}
          >
            {working ? "Working..." : "Proceed"}
          </button>
        </div>
      </form>
    </Modal>
  );
}
