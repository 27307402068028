import React from "react";
import Axios from "axios";
import classNames from "classnames";
import Modal from "react-modal";

import API from "../utils/api";
import ErrorAlert from "./ErrorAlert";
import SRVRecordForm from "./SRVRecordForm";

export default function EditDNSRecord({ domain, record, onChanged, close }) {
  const [form, setForm] = React.useState({
    type: "",
    name: "",
    content: "",
    ttl: "",
    proxied: "",
    priority: "",
    data: {
      proto: "_tcp",
      service: "",
      name: "",
      weight: "0",
      port: "",
      target: "",
      priority: "",
    },
  });
  const [error, setError] = React.useState(null);
  const [working, setWorking] = React.useState(false);

  React.useEffect(() => {
    if (form.type === "SRV") {
      const content = `${form.priority} ${form.data.weight} ${form.data.port} ${form.data.target}`;
      const name = `${form.data.service}.${form.data.proto}.${form.data.name}`;
      const nForm = {
        ...form,
        content,
        name,
        data: { ...form.data, priority: form.priority },
        proxiable: false,
      };
      if (
        form.content !== content ||
        form.name !== name ||
        form.priority !== form.data.priority ||
        !("proxiable" in form)
      ) {
        setForm(nForm);
      }
    }
  }, [form]);

  React.useEffect(() => {
    setForm({
      type: record.type,
      name: record.name,
      content: record.content,
      ttl: record.ttl,
      proxied: record.proxied,
      priority: record.priority,
      data: record.data,
    });
  }, [record]);

  const proceed = (e) => {
    e.preventDefault();
    setWorking(true);
    Axios.post(API.domains.updateDNS(domain, record.id), form)
      .then((resp) => {
        const { success, result, error, errors } = resp.data;
        if (error || errors.length > 0) return setError(error || errors);
        if (success && result) {
          onChanged(result);
        }
      })
      .catch(setError)
      .finally(() => (error ? setWorking(false) : close()));
  };

  const styles = {
    overlay: {
      backgroundColor: "rgb(15 15 15 / 75%)",
      zIndex: 999,
    },
  };

  return (
    <Modal isOpen={true} style={styles} contentLabel="Editing DNS Records">
      <h2>Editing DNS Record</h2>
      <form onSubmit={proceed}>
        <div className="form-row mb-2">
          <div className="col-md-2">
            <label>Type</label>
            <select
              className="custom-select"
              value={form.type}
              onChange={({ target: { value } }) => {
                const nState = { ...form, type: value };
                if (value === "MX") {
                  nState.proxied = "false";
                  nState.priority = 10;
                }
                if (["TXT", "CNAME"].includes(value)) nState.proxied = "false";
                setForm(nState);
              }}
            >
              <option value="" disabled>
                Select
              </option>
              <option value="A">A</option>
              <option value="AAAA">AAAA</option>
              <option value="CNAME">CNAME</option>
              <option value="TXT">TXT</option>
              <option value="SRV">SRV</option>
              <option value="LOC">LOC</option>
              <option value="MX">MX</option>
              <option value="NS">NS</option>
              <option value="SPF">SPF</option>
              <option value="CERT">CERT</option>
              <option value="DNSKEY">DNSKEY</option>
              <option value="DS">DS</option>
              <option value="NAPTR">NAPTR</option>
              <option value="SMIMEA">SMIMEA</option>
              <option value="SSHFP">SSHFP</option>
              <option value="TLSA">TLSA</option>
              <option value="URI">URI</option>
            </select>
          </div>
          <div className="col-md-4">
            <label>Name</label>
            <input
              type="text"
              className="form-control"
              value={form.name}
              onChange={(e) => setForm({ ...form, name: e.target.value })}
              maxLength="255"
              disabled={["SRV"].includes(form.type)}
            />
          </div>
          <div className="col-md-2">
            <label>TTL</label>
            <input
              type="number"
              className="form-control"
              value={form.ttl}
              onChange={(e) => setForm({ ...form, ttl: e.target.value })}
            />
          </div>
          <div className="col-md-2">
            <label>Proxied</label>
            <select
              className="custom-select"
              value={form.proxied}
              onChange={(e) => setForm({ ...form, proxied: e.target.value })}
              disabled={["MX", "SRV"].includes(form.type)}
            >
              <option value="" disabled>
                N/A
              </option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </div>
          <div className="col-md-2">
            {["MX", "SRV"].includes(form.type) && (
              <>
                <label>Priority</label>
                <input
                  type="number"
                  className="form-control"
                  value={form.priority}
                  min="0"
                  max="65535"
                  onChange={(e) =>
                    setForm({ ...form, priority: e.target.value })
                  }
                />
              </>
            )}
          </div>
        </div>
        <fieldset className="form-group">
          <label>Content</label>
          <textarea
            type="text"
            className="form-control"
            value={form.content}
            onChange={(e) => setForm({ ...form, content: e.target.value })}
            disabled={["SRV"].includes(form.type)}
          />
        </fieldset>
        <SRVRecordForm form={form} setForm={setForm} />
        <div className="form-row" style={{ alignItems: "center" }}>
          <div className="col-md-9 mr-auto" style={{ minHeight: 50 }}>
            <ErrorAlert
              style={{ margin: 0 }}
              error={error}
              setError={setError}
            />
          </div>
          <div className="col-md-3 ml-auto text-right">
            <button
              onClick={close}
              type="button"
              className="btn btn-secondary px-3 mr-2"
            >
              Cancel
            </button>
            <button
              className={classNames("btn", "btn-primary", "px-3", {
                disabled: working,
              })}
              disabled={working}
            >
              {working ? "Working..." : "Proceed"}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
}
