import React from "react";

export default function SRVRecordForm({ form, setForm }) {
  if (form.type !== "SRV" || !form.data) return <span form-data={form.data} />;
  const { service, proto, target, weight, port, name } = form.data;
  const changeValue = (e) =>
    setForm({
      ...form,
      data: {
        ...form.data,
        [e.target.name]: e.target.value,
      },
    });

  return (
    <div className="srv-record-form-group mb-3">
      <h4 className="text-uppercase h5">SRV Details</h4>
      <div className="form-group form-row">
        <div className="col-md-3">
          <label>Service</label>
          <input
            type="text"
            className="form-control"
            value={service}
            name="service"
            placeholder="_servicename"
            onChange={changeValue}
          />
        </div>
        <div className="col-md-3">
          <label>Name</label>
          <input
            type="text"
            className="form-control"
            name="name"
            value={name}
            onChange={changeValue}
          />
        </div>
        <div className="col-md-2">
          <label>Protocol</label>
          <select
            name="proto"
            value={proto}
            onChange={changeValue}
            className="custom-select"
          >
            <option value="_tcp">TCP</option>
            <option value="_udp">UDP</option>
            <option value="_tls">TLS</option>
          </select>
        </div>
        <div className="col-md-2">
          <label>Weight</label>
          <input
            type="number"
            className="form-control"
            placeholder="0 - 65535"
            value={weight}
            onChange={changeValue}
            name="weight"
          />
        </div>
        <div className="col-md-2">
          <label>Port</label>
          <input
            type="number"
            className="form-control"
            value={port}
            onChange={changeValue}
            name="port"
            placeholder="0 - 65535"
          />
        </div>
      </div>
      <fieldset className="form-group">
        <label>Target</label>
        <textarea
          name="target"
          value={target}
          onChange={changeValue}
          className="form-control"
        />
      </fieldset>
    </div>
  );
}
