import React from "react";
import Axios from "axios";
import classNames from "classnames";

import API from "../../utils/api";
import ErrorAlert from "../ErrorAlert";
import {
  settingsDetails,
  challengeTtlOptions,
  securityLevels,
} from "./commons";
import { longDate } from "../../utils";

export default function FirewallSettings({ domainId }) {
  const [error, setError] = React.useState();
  const [settings, setSettings] = React.useState([]);
  const [working, setWorking] = React.useState(-1);

  React.useEffect(() => {
    Axios.get(API.firewall.settings.get(domainId))
      .then((resp) => {
        const { settings, error } = resp.data;
        if (error) return setError(error);
        if (settings) {
          setSettings(settings);
        }
      })
      .catch(setError);
  }, [domainId]);

  const RenderInput = ({ i, setting, value }) => {
    const onChange = (e) => {
      let newValue;
      setWorking(i);
      if (["browser_check", "privacy_pass"].includes(setting)) {
        newValue = value === "on" ? "off" : "on";
      } else {
        newValue = e.target.value;
      }
      Axios.post(API.firewall.settings.save(domainId, setting), {
        value: newValue,
      })
        .then((resp) => {
          const { success, error, errors, result } = resp.data;
          if (error || errors.length > 0) return setError(error || errors[0]);
          if (success && result) {
            const list = [...settings];
            list[i] = result;
            setSettings(list);
          }
        })
        .catch(setError)
        .finally(() => setWorking(-1));
    };
    if (setting === "browser_check") {
      return (
        <div className="custom-control custom-switch">
          <input
            value={value}
            onChange={onChange}
            type="checkbox"
            className="custom-control-input"
            id="browser_check"
            checked={value === "on"}
          />
          <label className="custom-control-label" htmlFor="browser_check" />
        </div>
      );
    }
    if (setting === "challenge_ttl") {
      return (
        <select value={value} onChange={onChange} className="custom-select">
          {challengeTtlOptions.map((k, i) => (
            <option key={i} value={k}>
              {k}
            </option>
          ))}
        </select>
      );
    }
    if (setting === "privacy_pass") {
      return (
        <div className="custom-control custom-switch">
          <input
            value={value}
            onChange={onChange}
            type="checkbox"
            className="custom-control-input"
            id="privacy_pass"
            checked={value === "on"}
          />
          <label className="custom-control-label" htmlFor="privacy_pass" />
        </div>
      );
    }
    if (setting === "security_level") {
      return (
        <select
          value={value}
          onChange={onChange}
          className="custom-select text-capitalize"
        >
          {securityLevels.map((k, i) => (
            <option key={i} value={k}>
              {k}
            </option>
          ))}
        </select>
      );
    }
  };

  return (
    <div className="firewall-settings py-2">
      <h3>Firewall Settings</h3>
      <ErrorAlert error={error} setError={setError} />
      <div className="container">
        {settings.map((setting, i) => (
          <div
            className="setting mb-3 border rounded row items-center bg-light shadow-sm"
            key={setting.id}
          >
            <div className="col-md-8 bg-white">
              <div className="py-3">
                <h5>{settingsDetails[setting.id].label}</h5>
                <p>{settingsDetails[setting.id].description}</p>
                <em className="text-muted">
                  Last Updated:{" "}
                  {setting.modified_on ? longDate(setting.modified_on) : "N/A"}
                </em>
              </div>
            </div>
            <div className={classNames("col-md-4", { loading: working === i })}>
              <div className="py-2 text-center">
                <RenderInput i={i} setting={setting.id} value={setting.value} />
              </div>
              {working === i && (
                <div className="loader">
                  <span className="spinner-border" style={{ color: "#000" }} />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
