import React from "react";
import Axios from "axios";
import { Link } from "react-router-dom";

import API from "../../utils/api";
import ErrorAlert from "../../components/ErrorAlert";
import Paginate from "../../components/Pagination";
import { longDate } from "../../utils";

export default function LoadBalancers() {
  const [error, setError] = React.useState(null);
  const [loadBalancers, setLoadBalancers] = React.useState({ docs: [] });
  const [query, setQuery] = React.useState({ page: 1 });

  React.useEffect(() => {
    Axios.get(API.loadBalancers.all(query))
      .then((resp) => {
        const { error, loadBalancers } = resp.data;
        if (error) return setError(error);
        if (loadBalancers) setLoadBalancers(loadBalancers);
      })
      .catch(setError);
  }, [query]);

  const deleteLoadBalancer = (i) => {
    Axios.delete(API.loadBalancers.delete(loadBalancers.docs[i]._id))
      .then((resp) => {
        const { error, success } = resp.data;
        if (error) return setError(error);
        if (success) {
          const docs = [...loadBalancers.docs];
          docs.splice(i, 1);
          setLoadBalancers({ ...loadBalancers, docs });
        }
      })
      .catch(setError);
  };

  return (
    <div className="load-balancers m-4">
      <div className="p-3 shadow-sm bg-white">
        <div className="row mb-3 mx-auto" style={{ alignItems: "center" }}>
          <div className="col-md-4">
            <h1 className="h2 m-0">Load Balancers</h1>
          </div>
          <div className="col-md-2 mr-auto">
            <Link
              to="/load-balancers/add-new"
              className="btn btn-sm btn-outline-primary px-4"
            >
              Add New
            </Link>
          </div>
        </div>
        <ErrorAlert error={error} setError={setError} />
        <div className="data">
          <table className="table">
            <thead>
              <tr>
                <th>Group</th>
                <th>IP1</th>
                <th>IP2</th>
                <th>Current IP</th>
                <th>Last Updated</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {loadBalancers.docs.map((loadBalancer, i) => (
                <tr key={loadBalancer._id}>
                  <td>{loadBalancer.group}</td>
                  <td>{loadBalancer.ips[0]}</td>
                  <td>{loadBalancer.ips[1]}</td>
                  <td>{loadBalancer.currentIp + 1}</td>
                  <td>{longDate(loadBalancer.updatedAt)}</td>
                  <td>
                    <Link to={"/load-balancers/" + loadBalancer._id}>Edit</Link>
                    {" - "}
                    <span
                      className="text-danger pointer"
                      onClick={() => deleteLoadBalancer(i)}
                    >
                      Delete
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Paginate
            query={query}
            setQuery={setQuery}
            total={loadBalancers.totalPages}
            small
          />
        </div>
      </div>
    </div>
  );
}
