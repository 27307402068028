import React from "react";
import Axios from "axios";
import classNames from "classnames";

import API from "../../utils/api";
import ErrorAlert from "../../components/ErrorAlert";
import Paginate from "../../components/Pagination";
import NewDNSRecord from "../../components/NewDNSRecord";
import EditDNSRecord from "../../components/EditDNSRecord";

export default function EditDomainDNS({
  match: {
    params: { id },
  },
}) {
  const [editing, setEditing] = React.useState(-1);
  const [adding, setAdding] = React.useState(true);
  const [domain, setDomain] = React.useState(null);
  const [query, setQuery] = React.useState({ page: 1 });
  const [error, setError] = React.useState(null);
  const [working, setWorking] = React.useState(true);
  const [records, setRecords] = React.useState({
    list: [],
    loading: true,
    pagination: { total_pages: 0, count: 0 },
  });

  React.useEffect(() => {
    setWorking(true);
    Axios.get(API.domains.getDNS(id, query))
      .then((resp) => {
        const { error, cf, domain } = resp.data;
        if (error) return setError(error);
        const { result, errors, result_info, success } = cf;
        if (Array.isArray(errors) && errors.length > 0) {
          return setError(errors);
        }
        if (domain) setDomain(domain);
        if (success) {
          setRecords({
            list: result,
            pagination: result_info,
          });
        }
      })
      .catch(setError)
      .finally(() => setWorking(false));
  }, [id, query]);

  const deleteRecord = (i) => {
    setWorking(true);
    Axios.delete(API.domains.delete(domain._id, records.list[i].id))
      .then((resp) => {
        const { error, success, errors } = resp.data;
        if (error || errors.length > 0) return setError(error || errors);
        if (success) {
          const list = [...records.list];
          list.splice(i, 1);
          setRecords({ ...records, list });
        }
      })
      .catch(setError)
      .finally(() => setWorking(false));
  };

  const deleteAllRecords = (e) => {
    e.preventDefault();
    if (!window.confirm("Are you sure?")) return;
    setWorking(true);
    Axios.delete(API.domains.deleteAllRecords(domain._id))
      .then((resp) => {
        const { error, success } = resp.data;
        if (error) return setError(error);
        if (success) {
          setRecords({ ...records, list: [] });
        }
      })
      .catch(setError)
      .finally(() => setWorking(false));
  };

  return (
    <div className="edit-domain-dns m-4">
      <div className={classNames("shadow-sm", "bg-white", "p-3", { working })}>
        <h1>Edit DNS</h1>
        <ErrorAlert error={error} setError={setError} />
        <div className="data border rounded">
          {domain && (
            <div className="px-2 py-4">
              <div className="row m-auto mb-2" style={{ alignItems: "center" }}>
                <div className="col-md-6">
                  <h4 className="m-0 align-middle">
                    <b>{domain.name}</b> DNS Management
                  </h4>
                </div>
                <div className="col-md-3 ml-auto text-right">
                  <button
                    onClick={() => setAdding(!adding)}
                    className="btn btn-outline-primary px-4"
                  >
                    {adding ? "Cancel" : "Add Record"}
                  </button>
                </div>
              </div>
              {adding && (
                <NewDNSRecord
                  domain={id}
                  onCreated={(result) =>
                    setRecords({ ...records, list: [...records.list, result] })
                  }
                  close={() => setAdding(false)}
                />
              )}
            </div>
          )}
          {records.loading && (
            <div className="text-center py-5">Please wait...</div>
          )}
          {records.list.length > 0 && (
            <div className="table-responsive">
              <table
                className="table w-100 m-0"
                style={{ tableLayout: "fixed" }}
              >
                <thead>
                  <tr>
                    <th style={{ width: 100 }}>Type</th>
                    <th style={{ width: "30%" }}>Name</th>
                    <th style={{ width: "30%" }}>Content</th>
                    <th style={{ width: 50 }}>TTL</th>
                    <th style={{ width: 75 }}>Proxy</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {records.list.map((record, i) => (
                    <tr key={record.id}>
                      <td>{record.type}</td>
                      <td>
                        <div className="overflow-hidden whitespace-nowrap textoverflow-ellipsis">
                          {record.name}
                        </div>
                      </td>
                      <td>
                        <div className="overflow-hidden whitespace-nowrap textoverflow-ellipsis">
                          {record.content}
                          {record.priority && (
                            <span
                              title="Priority"
                              className="ml-2 bg-info rounded text-white p-1"
                            >
                              {record.priority}
                            </span>
                          )}
                        </div>
                      </td>
                      <td>{record.ttl}</td>
                      <td>{record.proxied ? "Yes" : "No"}</td>
                      <td>
                        <span
                          className="text-info pointer"
                          onClick={() => setEditing(i)}
                        >
                          Edit
                        </span>
                        {" - "}
                        <span
                          className="text-danger pointer"
                          onClick={() => deleteRecord(i)}
                        >
                          Delete
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <Paginate
          query={query}
          setQuery={setQuery}
          total={records.pagination.total_pages}
          small
        />
        <div className="mt-4 text-center">
          {records.list && records.list.length > 0 && (
            <button onClick={deleteAllRecords} className="btn btn-danger px-3">
              Delete All Records
            </button>
          )}
        </div>
        {working && (
          <div className="loader">
            <div className="spinner-grow" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </div>
      {domain && records.list[editing] && (
        <EditDNSRecord
          record={records.list[editing]}
          close={() => setEditing(-1)}
          domain={domain._id}
          onChanged={(result) => {
            const list = [...records.list];
            list[editing] = result;
            setRecords({ ...records, list });
          }}
        />
      )}
    </div>
  );
}
