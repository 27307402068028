import React from "react";
import Axios from "axios";

import API from "../utils/api";
import ErrorAlert from "../components/ErrorAlert";
import StatisticsGraph from "../components/Graph";

export default function Statistics() {
  const [error, setError] = React.useState(null);
  const [domains, setDomains] = React.useState([]);
  const [selected, setSelected] = React.useState(-1);

  React.useEffect(() => {
    Axios.get(API.domains.quickList)
      .then((resp) => {
        const { domains, error } = resp.data;
        if (error) return setError(error);
        if (domains) setDomains(domains);
      })
      .catch(setError);
  }, []);

  return (
    <div className="statistics m-4">
      <div className="p-3 shadow-sm bg-white">
        <div className="row" style={{ alignItems: "center" }}>
          <div className="col-md-4 mr-auto">
            <h1>Statistics</h1>
          </div>
          <div className="col-md-4 ml-auto">
            <select
              className="custom-select"
              value={selected}
              onChange={(e) => setSelected(e.target.value)}
            >
              <option value="-1">Select a Domain</option>
              {domains.map((domain, i) => (
                <option key={domain._id} value={i}>
                  {domain.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <ErrorAlert error={error} setError={setError} />
        <div className="mt-3">
          {domains[selected] ? (
            <StatisticsGraph domain={domains[selected]} />
          ) : (
            <div className="py-5 text-center border rounded">
              <p className="m-0" style={{ fontSize: 20 }}>
                Please select a domain.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
